@import "@jusbrasil/mystique/scss/variables";

:export {
  zIndexModal: map-get($zindex, modal);
}

$mobile-breakpoint: map-get($grid-breakpoints, sm);

:export {
  mobileBreakpoint: $mobile-breakpoint;
}

@media (min-width: map-get($grid-breakpoints, sm)) {
  body {
    background-color: transparent;
  }
}

.bifrost-uncaught-error {
  &-body-iframe {
    background-color: $black60;
  }

  &-content {
    align-items: center;
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    max-width: 480px;
    margin: 10vh auto 0;
    padding: $spacer*2
  }

  &-image {
    margin: $spacer*3;
    max-width: 90%;
  }

  &-debug-message {
    font-size: x-small;
    color: $gray;
    max-height: 86px;
    max-width: 80%;
    overflow: hidden;
  }
}
